<template>
  <div class="list-checkout">
    <div class="list-checkout-table">
      <div class="mb-2 d-flex justify-content-between">
        <h2>スラグ</h2>
      </div>
      <Tables :items="listOrder" :fields="fields" :itemsPerPage="10">
        <template v-slot:created_at="{ item }">
          <td>
            {{
              new Date(item.created_at).toLocaleString("ja-JP", {
                timeZone: "Asia/Tokyo",
              })
            }}
          </td>
        </template>
        <template v-slot:status_order="{ item }">
          <td v-if="item.status_order == 0">
            <div class="bg-primary d-flex justify-content-center">Waiting</div>
          </td>
          <td v-else-if="item.status_order == 1">
            <div class="bg-success d-flex justify-content-center">
              Confirmed
            </div>
          </td>
          <td v-else-if="item.status_order == 2">
            <div class="bg-danger d-flex justify-content-center">
              Unconfimred
            </div>
          </td>
          <td v-else-if="item.status_order == 3">
            <div class="bg-success d-flex justify-content-center">Paid</div>
          </td>
          <td v-else-if="item.status_order == 4">
            <div class="bg-danger d-flex justify-content-center">UnPaid</div>
          </td>
        </template>
        <template v-slot:payment_type="{ item }">
          <td v-if="item.payment_type == 0">Paypal</td>
          <td v-else>Stripe</td>
        </template>
        <template v-slot:action="{ item }">
          <td class="py-2 pl-2 pr-1 d-flex justify-content-center">
            <CButton
              class="mx-1"
              v-b-popover.hover.top="'check'"
              color="success"
              square
              v-on:click="orderComplete(item.id)"
              variant="outline"
              size="sm"
              v-b-modal.modal-comfirm
            >
              <font-awesome-icon
                class="sidebar-icon"
                :icon="['fas', 'check']"
              />
            </CButton>
            <CButton
              class="mx-1"
              v-b-popover.hover.top="'defeat'"
              color="danger"
              square
              v-on:click="orderNotComplete(item.id)"
              variant="outline"
              size="sm"
              v-b-modal.delete-modal-user
            >
              <font-awesome-icon
                class="sidebar-icon"
                :icon="['fas', 'times']"
              />
            </CButton>
            <CButton
              class="mx-1"
              v-b-popover.hover.top="'detail'"
              color="primary"
              square
              v-on:click="showDetail(item.id)"
              variant="outline"
              size="sm"
              v-b-modal.delete-modal-user
            >
              <font-awesome-icon class="sidebar-icon" :icon="['fas', 'eye']" />
            </CButton>
          </td>
        </template>
      </Tables>
    </div>
  </div>
</template>

<script>
import Tables from "../../components/common/Tables";
import { tableFields } from "../../utils/table-fields";
import { mapActions, mapGetters } from "vuex";
import { Constants } from "../../utils/constants";
export default {
  name: "Checkout",
  components: {
    Tables,
  },
  data() {
    return {
      fields: tableFields.CHECKOUT,
      dataModal: "",
    };
  },
  created() {
    const shop_id = localStorage.getItem(Constants.SHOP_ID);
    this.getAllOrders(shop_id);
  },
  computed: {
    ...mapGetters(["listOrder", "success", "message", "error"]),
  },
  watch: {
    success() {
      if (this.success) {
        this.$toasted.success(this.message);
        this.$store.commit("set", ["success", false]);
        this.$store.commit("set", ["message", ""]);
        setTimeout(() => {
          this.$router.go();
        }, 1000);
      }
    },
    error() {
      if (this.error) {
        this.$toasted.error(this.message);
        this.$store.commit("set", ["message", ""]);
        this.$store.commit("set", ["error", false]);
      }
    },
  },
  methods: {
    ...mapActions({ getAllOrders: "getAllOrders" }),
    ...mapActions({ confirmPaymentComplete: "confirmPaymentComplete" }),
    ...mapActions({ confirmPaymentNotComplete: "confirmPaymentNotComplete" }),
    showDetail(id) {
      this.$router.push({
        name: this.$route.params.shopId
          ? "checkout detail"
          : "checkout detail domain",
        params: { id: id },
      });
    },
    async orderComplete(id) {
      const formData = {
        id: id,
        status_order: 1,
      };
      await this.confirmPaymentComplete(formData);
    },
    async orderNotComplete(id) {
      const formData = {
        id: id,
        status_order: 2,
      };
      await this.confirmPaymentComplete(formData);
    },
  },
};
</script>

<style lang="scss">
.list-checkout {
  padding: 20px;
  .list-checkout-table {
    padding: 25px;
    background-color: #fff;
    border-radius: 5px;
  }
}
</style>
